import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import AddIcon from "@mui/icons-material/Add";
import {
  Container,
  Grid,
  TextField,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const AddCompetitionCoach = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const [competition, setCompetition] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [dVault1, setDVault1] = useState("");
  const [eVault1, setEVault1] = useState("");
  const [nVault1, setNVault1] = useState("");
  const [tVault1, setTVault1] = useState("");
  const [dVault2, setDVault2] = useState("");
  const [eVault2, setEVault2] = useState("");
  const [nVault2, setNVault2] = useState("");
  const [tVault2, setTVault2] = useState("");
  const [pVault, setPVault] = useState("");
  const [dPBars, setDPBars] = useState("");
  const [ePBars, setEPBars] = useState("");
  const [nPBars, setNPBars] = useState("");
  const [tPBars, setTPBars] = useState("");
  const [pPBars, setPPBars] = useState("");
  const [dHBars, setDHBars] = useState("");
  const [eHBars, setEHBars] = useState("");
  const [nHBars, setNHBars] = useState("");
  const [tHBars, setTHBars] = useState("");
  const [pHBars, setPHBars] = useState("");
  const [dRings, setDRings] = useState("");
  const [eRings, setERings] = useState("");
  const [nRings, setNRings] = useState("");
  const [tRings, setTRings] = useState("");
  const [pRings, setPRings] = useState("");
  const [dHorse, setDHorse] = useState("");
  const [eHorse, setEHorse] = useState("");
  const [nHorse, setNHorse] = useState("");
  const [tHorse, setTHorse] = useState("");
  const [pHorse, setPHorse] = useState("");
  const [dFloor, setDFloor] = useState("");
  const [eFloor, setEFloor] = useState("");
  const [nFloor, setNFloor] = useState("");
  const [tFloor, setTFloor] = useState("");
  const [pFloor, setPFloor] = useState("");
  const [tTotal, setTTotal] = useState("");
  const [pTotal, setPTotal] = useState("");
  const [evaluation, setEvaluation] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `Competitions/${id}/userCompetitions`);

    await addDoc(ref, {
      competition: competition,
      date: date,
      location: location,
      dVault1: dVault1,
      eVault1: eVault1,
      nVault1: nVault1,
      tVault1: tVault1,
      dVault2: dVault2,
      eVault2: eVault2,
      nVault2: nVault2,
      tVault2: tVault2,
      pVault: pVault,
      dPBars: dPBars,
      ePBars: ePBars,
      nPBars: nPBars,
      tPBars: tPBars,
      pPBars: pPBars,
      dHBars: dHBars,
      eHBars: eHBars,
      nHBars: nHBars,
      tHBars: tHBars,
      pHBars: pHBars,
      dRings: dRings,
      eRings: eRings,
      nRings: nRings,
      tRings: tRings,
      pRings: pRings,
      dHorse: dHorse,
      eHorse: eHorse,
      nHorse: nHorse,
      tHorse: tHorse,
      pHorse: pHorse,
      dFloor: dFloor,
      eFloor: eFloor,
      nFloor: nFloor,
      tFloor: tFloor,
      pFloor: pFloor,
      tTotal: tTotal,
      pTotal: pTotal,
      evaluation: evaluation,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setCompetition("");
    setDate("");
    setLocation("");
    setDVault1("");
    setEVault1("");
    setNVault1("");
    setTVault1("");
    setDVault2("");
    setEVault2("");
    setNVault2("");
    setTVault2("");
    setPVault("");
    setDPBars("");
    setEPBars("");
    setNPBars("");
    setTPBars("");
    setPPBars("");
    setDHBars("");
    setEHBars("");
    setNHBars("");
    setTHBars("");
    setPHBars("");
    setDRings("");
    setERings("");
    setNRings("");
    setTRings("");
    setPRings("");
    setDHorse("");
    setEHorse("");
    setNHorse("");
    setTHorse("");
    setPHorse("");
    setDFloor("");
    setEFloor("");
    setNFloor("");
    setTFloor("");
    setPFloor("");
    setTTotal("");
    setPTotal("");
    setEvaluation("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <TextField
                label="Wedstrijd"
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={competition}
                onChange={(event) => setCompetition(event.target.value)}
              />
            </Grid>
            <Grid item xs={7} md={4}>
              <TextField
                label="Locatie"
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={location}
                onChange={(event) => setLocation(event.target.value)}
              />
            </Grid>
            <Grid item xs={5} md={4}>
              <TextField
                label="Datum"
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={date}
                onChange={(event) => setDate(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container mt={1} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}></Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Vloer
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Voltige
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Ringen
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Sprong 1
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Sprong 2
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Brug
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Rek
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Totaal
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  D-Score
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dFloor}
                onChange={(event) => setDFloor(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dHorse}
                onChange={(event) => setDHorse(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dRings}
                onChange={(event) => setDRings(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dVault1}
                onChange={(event) => setDVault1(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dVault2}
                onChange={(event) => setDVault2(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dPBars}
                onChange={(event) => setDPBars(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dHBars}
                onChange={(event) => setDHBars(event.target.value)}
              />
            </Grid>
       
          </Grid>
          <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  E-Score
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={eFloor}
                onChange={(event) => setEFloor(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={eHorse}
                onChange={(event) => setEHorse(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={eRings}
                onChange={(event) => setERings(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={eVault1}
                onChange={(event) => setEVault1(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={eVault2}
                onChange={(event) => setEVault2(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={ePBars}
                onChange={(event) => setEPBars(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={eHBars}
                onChange={(event) => setEHBars(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  Neutraal
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={nFloor}
                onChange={(event) => setNFloor(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={nHorse}
                onChange={(event) => setNHorse(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={nRings}
                onChange={(event) => setNRings(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={nVault1}
                onChange={(event) => setNVault1(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={nVault2}
                onChange={(event) => setNVault2(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={nPBars}
                onChange={(event) => setNPBars(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={nHBars}
                onChange={(event) => setNHBars(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  Totaal
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tFloor}
                onChange={(event) => setTFloor(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tHorse}
                onChange={(event) => setTHorse(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tRings}
                onChange={(event) => setTRings(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tVault1}
                onChange={(event) => setTVault1(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tVault2}
                onChange={(event) => setTVault2(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tPBars}
                onChange={(event) => setTPBars(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tHBars}
                onChange={(event) => setTHBars(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tTotal}
                onChange={(event) => setTTotal(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  Plaats
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pFloor}
                onChange={(event) => setPFloor(event.target.value)}
              />
            </Grid>

            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pHorse}
                onChange={(event) => setPHorse(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pRings}
                onChange={(event) => setPRings(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pVault}
                onChange={(event) => setPVault(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pPBars}
                onChange={(event) => setPPBars(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pHBars}
                onChange={(event) => setPHBars(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pTotal}
                onChange={(event) => setPTotal(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  Evaluatie
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={9}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={evaluation}
                onChange={(event) => setEvaluation(event.target.value)}
              />
            </Grid>
            <Grid item md={1} justifyContent="end" display="flex">
              <Button
                variant="contained"
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                size="large"
                type="submit"
                sx={{ height: 40 }}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D Vloer
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  E Vloer
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  T Vloer
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  P Vloer
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dFloor}
                onChange={(event) => setDFloor(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={eFloor}
                onChange={(event) => setEFloor(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tFloor}
                onChange={(event) => setTFloor(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pFloor}
                onChange={(event) => setPFloor(event.target.value)}
              />
            </Grid>
            
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D Voltige
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  E Voltige
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  T Voltige
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  P Voltige
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
          <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dHorse}
                onChange={(event) => setDHorse(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={eHorse}
                onChange={(event) => setEHorse(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tHorse}
                onChange={(event) => setTHorse(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pHorse}
                onChange={(event) => setPHorse(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D Ringen
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  E Ringen
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  T Ringen
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  P Ringen
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
          <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dRings}
                onChange={(event) => setDRings(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={eRings}
                onChange={(event) => setERings(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tRings}
                onChange={(event) => setTRings(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pRings}
                onChange={(event) => setPRings(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D Sprong 1
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  E Sprong 1
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  T Sprong 1
                </Typography>
              </Paper>
            </Grid>
          
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
          <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dVault1}
                onChange={(event) => setDVault1(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={eVault1}
                onChange={(event) => setEVault1(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tVault1}
                onChange={(event) => setTVault1(event.target.value)}
              />
            </Grid>
           
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D Sprong 1
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  E Sprong 2
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  T Sprong 2
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  P Sprong 
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
          <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dVault2}
                onChange={(event) => setDVault2(event.target.value)}
              />
            </Grid>
           
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={eVault2}
                onChange={(event) => setEVault2(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tVault2}
                onChange={(event) => setTVault2(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pVault}
                onChange={(event) => setPVault(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D Brug
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  E Brug
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  T Brug
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  P Brug 
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
          <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dPBars}
                onChange={(event) => setDPBars(event.target.value)}
              />
            </Grid>
           
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={ePBars}
                onChange={(event) => setEPBars(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tPBars}
                onChange={(event) => setTPBars(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pPBars}
                onChange={(event) => setPPBars(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D Rek
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  E Rek
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  T Rek
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  P Rek
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
          <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={dHBars}
                onChange={(event) => setDHBars(event.target.value)}
              />
            </Grid>
           
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={eHBars}
                onChange={(event) => setEHBars(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tHBars}
                onChange={(event) => setTHBars(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pHBars}
                onChange={(event) => setPHBars(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={12}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Evaluation
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={12}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={evaluation}
                onChange={(event) => setEvaluation(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Total AA
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Place AA
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={tTotal}
                onChange={(event) => setTTotal(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                InputLabelProps={{
                    sx: {
                      fontSize: { xs: 10, md: 14, lg: 16 },
                    },
                  }}
                size="small"
                variant="outlined"
                fullWidth
                value={pTotal}
                onChange={(event) => setPTotal(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3} justifyContent="end" display="flex">
              <Button
                variant="contained"
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                
                size="large"
                type="submit"
                sx={{ height: 35 }}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
