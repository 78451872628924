import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { Grid } from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { AddCompetitionGymnast } from "./AddCompetitionGymnast";
import { idText } from "typescript";
import { AddCompetitionCoach } from "./AddCompetitionCoach";
import { CompetitionsListCoach } from "./CompetitionListCoach";
// import { AddCompetition2 } from "./AddCompetition2";
// import { CompetitionsList2 } from "./CompetitionsList2";

export const CompetitionPageCoach = () => {
  const { authIsReady, user } = useAuthContext();
  const [competitionsList, setCompetitionsList] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const ref = query(
      collection(db, `Competitions/${id}/userCompetitions`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setCompetitionsList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <AddCompetitionCoach />
      </Grid>
      <Grid container mt={4}>
        {competitionsList && (
          <CompetitionsListCoach competitions={competitionsList} />
        )}
      </Grid>
    </>
  );
};
