import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
  Divider,
} from "@mui/material";
// import { EditCompetition } from "./EditCompetition";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const CompetitionsListCoach = ({ competitions }: any) => {
  const { id } = useParams();

  return (
    <>
      {competitions.map((obj: any) => (
        <>
          <Container key={obj.id}>
            <Grid container display={{ xs: "none", md: "flex" }}>
              <Grid item md={4}>
                <Paper style={{ backgroundColor: PgColors.bluepg[200] }}>
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.competition}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={4}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  {" "}
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.location}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={4}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  {" "}
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.date}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container mt={1} display={{ xs: "none", md: "flex" }}>
              <Grid item md={2}></Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Vloer
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Voltige
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Ringen
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Sprong 1
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Sprong 2
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Brug
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Rek
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={2}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Totaal
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "none", md: "flex" }}>
              <Grid item md={2}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    D-score
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.dFloor}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.dHorse}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.dRings}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.dVault1}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.dVault2}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.dPBars}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.dHBars}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "none", md: "flex" }}>
              <Grid item md={2}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    E-score
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.eFloor}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.eHorse}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.eRings}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.eVault1}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.eVault2}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.ePBars}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.eHBars}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "none", md: "flex" }}>
              <Grid item md={2}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Neutraal
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.nFloor}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.nHorse}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.nRings}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.nVault1}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.nVault2}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.nPBars}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.nHBars}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "none", md: "flex" }}>
              <Grid item md={2}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Totaal
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.tFloor}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.tHorse}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.tRings}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.tVault1}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  {" "}
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.tVault2}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  {" "}
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.tPBars}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  {" "}
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.tHBars}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={2}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  {" "}
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.tTotal}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "none", md: "flex" }}>
              <Grid item md={2}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Plaats
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.pFloor}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.pHorse}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.pRings}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={2}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.pVault}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.pPBars}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.pHBars}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={2}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.pTotal}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container mb={4} display={{ xs: "none", md: "flex" }}>
              <Grid item md={2}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Evaluatie
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={9}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    {obj.evaluation}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1} justifyContent="end" display="flex">
                {/* <EditCompetition
                  path={`Competitions/${id}/userCompetitions/${obj.id}`}
                /> */}
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={5}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.competition}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.location}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.date}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }} marginTop={1}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    D Vloer
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    E Vloer
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    T Vloer
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    P Vloer
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.dFloor}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.eFloor}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.tFloor}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.pFloor}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    D Voltige
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    E Voltige
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    T Voltige
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    P Voltige
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.dHorse}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.eHorse}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.tHorse}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.pHorse}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    D Ringen
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    E Ringen
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    T Ringen
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    P Ringen
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.dRings}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.eRings}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.tRings}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.pRings}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    D Sprong 1
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    E Sprong 1
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    T Sprong 1
                  </Typography>
                </Paper>
              </Grid>
             
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.dVault1}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.eVault1}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.tVault1}
                  </Typography>
                </Paper>
              </Grid>
          
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    D Sprong 2
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    E Sprong 2
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    T Sprong 2
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    P Sprong
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.dVault2}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.eVault2}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.tVault2}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.pVault}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    D Brug
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    E Brug
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    T Brug
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    P Brug
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.dPBars}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.ePBars}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.tPBars}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.pPBars}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    D Rek
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    E Rek
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    T Rek
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    P Rek
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.dHBars}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.eHBars}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.tHBars}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.pHBars}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={12}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    Evaluatie
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={12}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.evaluation}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    Totaal
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    Plaats
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid
              container
              display={{ xs: "flex", md: "none" }}
              marginBottom={4}
            >
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.tTotal}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    {obj.pTotal}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid
                item
                xs={3}
                justifyContent="end"
                display="flex"
                marginTop={-3}
              >
                {/* <EditCompetition
                  path={`Competitions/${id}/userCompetitions/${obj.id}`}
                /> */}
              </Grid>
            </Grid>
          </Container>
        </>
      ))}
    </>
  );
};
