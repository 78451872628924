import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  updateDoc,
  doc,
  where,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { Grid, Container, Typography, Divider, Button, CardMedia, Box } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { PgColors } from "../../configs/colors";

export const Sprong = ({ path, gymnast }: any) => {
  const { authIsReady, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [programsGList, setProgramsGList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (authIsReady && user) {
      const ref = query(
        collection(db, path),
        orderBy("name", "asc"),
        where("apparatus", "==", "Sprong")
      );

      const unsub = onSnapshot(ref, (snapshot) => {
        let results: any = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setProgramsList(results);
      });
      return () => unsub();
    }
  }, [authIsReady, user]);

  useEffect(() => {
    if (gymnast.coachId) {
      const ref = query(
        collection(db, `ProgramsByCoach/${gymnast.coachId}/Programs`),
        orderBy("name", "asc"),
        where("apparatus", "==", "Sprong")
      );

      const unsub = onSnapshot(ref, (snapshot) => {
        let results: any = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setProgramsGList(results);
      });

      return () => unsub();
    }
  }, [gymnast.coachId]);

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Container>
        {/* <Grid mt={2}>
          <Typography
            variant="h6"
            style={{
              color: PgColors.bluepg[200],
              fontWeight: "500",
              fontSize: 24,
            }}
          >
            Sprong Programma's
          </Typography>
        </Grid> */}
           <Grid
          item
          mt={0}
          xs={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ display: "flex" }}>
            <CardMedia
              sx={{
                maxWidth: { xs: 340, sm: 600, md: 900, lg: 1200 },
              }}
              component="img"
              image="../images/vaultEl/TsukiHurkProgram.png"
              alt="symbol2"
            ></CardMedia>
          </Box>
        </Grid>
        <Grid
          item
          mt={0}
          xs={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ display: "flex" }}>
            <CardMedia
              sx={{
                maxWidth: { xs: 340, sm: 600, md: 900, lg: 1200 },
              }}
              component="img"
              image="../images/vaultEl/TkatchevProgram.png"
              alt="symbol2"
            ></CardMedia>
          </Box>
        </Grid>
        <Grid
          item
          mt={0}
          xs={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ display: "flex" }}>
            <CardMedia
              sx={{
                maxWidth: { xs: 340, sm: 600, md: 900, lg: 1200 },
              }}
              component="img"
              image="../images/vaultEl/SchroefProgram.png"
              alt="symbol2"
            ></CardMedia>
          </Box>
        </Grid>
        <Grid
          item
          mt={0}
          xs={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ display: "flex" }}>
            <CardMedia
              sx={{
                maxWidth: { xs: 340, sm: 600, md: 900, lg: 1200 },
              }}
              component="img"
              image="../images/vaultEl/LenigheidProgram.png"
              alt="symbol2"
            ></CardMedia>
          </Box>
        </Grid>
        <Grid
          item
          mt={0}
          xs={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ display: "flex" }}>
            <CardMedia
              sx={{
                maxWidth: { xs: 340, sm: 600, md: 900, lg: 1200 },
              }}
              component="img"
              image="../images/vaultEl/AfzetProgram.png"
              alt="symbol2"
            ></CardMedia>
          </Box>
        </Grid>
        <Grid
          item
          mt={0}
          xs={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ display: "flex" }}>
            <CardMedia
              sx={{
                maxWidth: { xs: 340, sm: 600, md: 900, lg: 1200 },
              }}
              component="img"
              image="../images/vaultEl/ReusProgram.png"
              alt="symbol2"
            ></CardMedia>
          </Box>
        </Grid>
        <Grid
          item
          mt={0}
          xs={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ display: "flex" }}>
            <CardMedia
              sx={{
                maxWidth: { xs: 340, sm: 600, md: 900, lg: 1200 },
              }}
              component="img"
              image="../images/vaultEl/ReusHalfProgram.png"
              alt="symbol2"
            ></CardMedia>
          </Box>
        </Grid>
        <Grid
          item
          mt={0}
          xs={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ display: "flex" }}>
            <CardMedia
              sx={{
                maxWidth: { xs: 340, sm: 600, md: 900, lg: 1200 },
              }}
              component="img"
              image="../images/vaultEl/LoopFlikflakProgram.png"
              alt="symbol2"
            ></CardMedia>
          </Box>
        </Grid>
        <Grid
          item
          mt={0}
          xs={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ display: "flex" }}>
            <CardMedia
              sx={{
                maxWidth: { xs: 340, sm: 600, md: 900, lg: 1200 },
              }}
              component="img"
              image="../images/vaultEl/FlankenProgram.png"
              alt="symbol2"
            ></CardMedia>
          </Box>
        </Grid>
        {programsList.map((program: any) => (
          <>
            <Grid container mt={2} key={program.id}>
              <Grid item>
                <Button
                  variant="text"
                  onClick={(e) => navigate(`${program.id}`)}
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: PgColors.bluepg[200],
                      fontWeight: "400",
                    }}
                    sx={{
                      fontSize: {
                        xs: 12,
                        md: 20,
                      },
                    }}
                  >
                    {program.name}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Divider></Divider>
          </>
        ))}
        {programsGList &&
          programsGList.map((program: any) => (
            <>
              <Grid container mt={2} key={program.id}>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={(e) => navigate(`${program.id}`)}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        color: PgColors.bluepg[200],
                        fontWeight: "400",
                      }}
                      sx={{
                        fontSize: {
                          xs: 12,
                          md: 20,
                        },
                      }}
                    >
                      {program.name}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Divider></Divider>
            </>
          ))}
      </Container>
    </>
  );
};
