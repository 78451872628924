import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { Grid } from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { AddCompetitionGymnast } from "./AddCompetitionGymnast";
import { CompetitionsListGymnast } from "./CompetitionListGymnast";


export const CompetitionPageGymnast = () => {
  const { authIsReady, user } = useAuthContext();
  const [competitionsList, setCompetitionsList] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (authIsReady && user) {
      const ref = query(
        collection(db, `Competitions/${user.uid}/userCompetitions`),
        orderBy("createdAt", "desc")
      );

      const unsub = onSnapshot(ref, (snapshot) => {
        let results: any = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setCompetitionsList(results);
      });
      return () => unsub();
    }
  }, [authIsReady, user]);

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Grid container mt={2}>
        <AddCompetitionGymnast />
      </Grid>
      <Grid container mt={4}>
        {competitionsList && (
          <CompetitionsListGymnast competitions={competitionsList} />
        )}
      </Grid>
    </>
  );
};
